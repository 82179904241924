import React from 'react';
import styles from './Navigation.module.css';

const NavLinks =  () => (
    <ul className={styles.navigationLinks}>
        <li className={styles.navigationLink}>
            <a href='#aboutMe'>About Me</a>
        </li>
        <li className={styles.navigationLink}>
            <a href='#projects'>Projects</a>
        </li>
    </ul>
);

export default NavLinks