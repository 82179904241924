import React from 'react';
import styles from './Footer.module.css';

const Footer = () => (
    <div className={styles.footerContainer}>
        <p className={styles.footerDetails}>Built using React, HTML, and CSS.</p>
        <a className={styles.footerDetails} href="mailto: ramirezjohnny@icloud.com">ramirezjohnny@icloud.com</a>
    </div>
);

export default Footer;