import React from 'react';
import Modal from 'react-modal';
import ProjectSwiper from './ProjectSwiper';
import styles from '../Projects.module.css';

const ProjectModal = ({
    isOpen,
    closeModal,
    projectImages,
    title, 
    description,
    languages,
    projectLink
}) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        className={styles.modal}
    >
        <ProjectSwiper projectImages={projectImages} />
        <h3 className={styles.modalHeader}>{title}</h3>
        <p>{description}</p>
        { 
            languages && languages.map((Language, key) => (
                <Language key={key} className={styles.languagesLogo}/>
            ))
        }
        <a href={projectLink} target='_blank' className={styles.viewProjectLink} rel="noreferrer">
            View { projectLink.includes('github') ? 'GitHub' : 'Website' }
        </a>
    </Modal>
);

export default ProjectModal;