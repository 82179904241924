import React from 'react';
import styles from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialCard = ({ icon, href }) => (
    <a href={href} className={styles.socialCard} target='_blank' rel="noreferrer">
        <FontAwesomeIcon icon={icon} />
    </a>
);

export default SocialCard;