import React, { useState } from 'react';
import ProjectModal from './Modal/ProjectModal';
import styles from './Projects.module.css';

const ProjectCard = ({ 
    title, 
    description, 
    languages, 
    images, 
    projectImages, 
    projectLink 
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => { 
        setIsOpen(false);
    };

    return (
        <>
            <div className={styles.projectCard} onClick={openModal}>
                <div className={styles.imageDiv}>
                    <img src={images} className={styles.images} alt='Project'/>
                </div>
                <div className={styles.descriptionContainer}>
                    <h2 className={styles.projectTitle}>{title}</h2>
                    <p className={styles.description}>{description}</p>
                    <div className={styles.languagesContainer}>
                    { 
                        languages && languages.map((Language, key) => (
                            <Language key={key} className={styles.languagesLogo}/>
                        ))
                    }
                    </div>
                </div>
            </div>
            <ProjectModal
                isOpen={modalIsOpen}
                closeModal={closeModal}
                projectImages={projectImages}
                title={title}
                description={description}
                languages={languages}
                projectLink={projectLink}
            />
        </>
    );
};

export default ProjectCard;