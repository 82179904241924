import React from 'react';
import SocialCard from './SocialCard';
import styles from './Header.module.css';
import profileImage from '../../assets/images/Profile/profile.jpeg';
import resume from '../../assets/John_Ramirez_Resume.pdf';
import { faEnvelope, faPhone, faFile } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const Header = () => (
    <div className={styles.header}>
        <img className={styles.profileImage} src={profileImage} alt="Johnny Ramirez" />
        <div className={styles.titles}>
            <h1 className={styles.name}>John Ramirez</h1>
            <h2 className={styles.description}>Web and Mobile Developer</h2>
            <h2 className={styles.description}>Princeton University Class of 2026</h2>
            <div className={styles.socialCards}>
                <SocialCard icon={faEnvelope} href='mailto:ramirezjohnny@icloud.com' />
                <SocialCard icon={faLinkedin} href='https://www.linkedin.com/in/john-ramirez-342439205' />
                <SocialCard icon={faGithub} href='https://github.com/johnnyr26' />
                <SocialCard icon={faFile} href={resume} />
            </div>
        </div>
    </div>
);

export default Header;