import React from 'react';
import Links from './Links';
import styles from './Navigation.module.css';

const NavigationHeader = () => (
    <div className={styles.navigationHeader}>
        <a className={styles.navTitle}>JR</a>
        <Links />
    </div>
);

export default NavigationHeader;