import React from 'react';
import image from '../../assets/images/AboutMeImage.png';
import styles from './AboutMe.module.css';

const AboutMe = () => {
    return (
        <div
            className={styles.aboutMeContainer}
            id="aboutMe"
        >
            <h3 className={styles.aboutMeHeading}>About Me</h3>
            <div className={styles.aboutMeContent}>
                <div
                    className={styles.aboutMeDescriptionContainer}
                >
                    <p className={styles.description}>
                        I am a first-year student at Princeton University studying Computer Science.
                        I am an software engineering fellow for Jane Street. 
                        I am also a software engineer for the Daily Princetonian.
                        During my free time, I enjoy swimming, biking and running around campus.
                    </p>
                </div>
                <img src={image} className={styles.aboutMeImage} alt='John Ramirez'/>
            </div>
        </div>
    );
};


export default AboutMe;