import React from 'react';
import Navigation from '../components/Navigation/NavigationHeader';
import Header from '../components/Header/Header';
import Projects from '../components/Projects/Projects';
import AboutMe from '../components/About_Me/AboutMe';
import Footer from '../components/Footer/Footer'

import styles from './HomePage.module.css';

const HomePage = () => (
    <div className={styles.homePage}>
        <Navigation />
        <Header />
        <AboutMe />
        <Projects />
        <Footer />
    </div>
);

export default HomePage;