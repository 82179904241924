import React from 'react';
import ProjectCard from './ProjectCard';

import bubbleLogo from '../../assets/images/Bubble/bubble.png';
import bbHubLogo from '../../assets/images/BB-Hub/bb-hub-logo.png';
import ovarscoreLogo from '../../assets/images/OvarScore/ovarscore.png';
import bbSchedulerLogo from '../../assets/images/BB-Scheduler/bb-scheduler.png';
import scheduleLogo from '../../assets/images/Schedule/schedule.png';
import dailyPrinceLogo from '../../assets/images/Daily_Princetonian/daily_prince.jpeg';
import { ReactComponent as appleLogo } from '../../assets/svg/apple.svg';
import { ReactComponent as androidLogo } from '../../assets/svg/android.svg';
import { ReactComponent as jsLogo } from '../../assets/svg/js.svg';
import { ReactComponent as tsLogo }from '../../assets/svg/ts.svg'
import { ReactComponent as nodeLogo } from '../../assets/svg/node.svg';
import { ReactComponent as reactLogo } from '../../assets/svg/react.svg';
import { ReactComponent as mongoLogo } from '../../assets/svg/mongo.svg';
import { ReactComponent as expressLogo } from '../../assets/svg/express.svg';
import { ReactComponent as htmlLogo } from '../../assets/svg/html.svg';
import { ReactComponent as ejsLogo } from '../../assets/svg/ejs.svg';
import { ReactComponent as pythonLogo } from '../../assets/svg/python.svg';


import bbHubImage1 from '../../assets/images/BB-Hub/bb-hub.png';
import bbHubImage2 from '../../assets/images/BB-Hub/bb-hub1.png';
import bubbleImage1 from '../../assets/images/Bubble/bubble_1.png';
import bubbleImage2 from '../../assets/images/Bubble/bubble_2.png';
import dailyPrinceImage1 from '../../assets/images/Daily_Princetonian/campus.png';
import dailyPrinceImage2 from '../../assets/images/Daily_Princetonian/sheet.png';
import bbSchedulerImage from '../../assets/images/BB-Scheduler/bb-scheduler.png';

import styles from './Projects.module.css';

const Projects = () => (
    <div 
        className={styles.projects_container}
        id="projects"
    >
        <div className={styles.projects}>
            <div className={styles.recentProjectsDiv}>   
                <h2 className={styles.recentProjectsTitle}>Recent Projects</h2>
                <h3 className={styles.recentProjectsInfo}>Click one for more information</h3>
            </div>
            <div className={styles.projectsDiv}>
                <ProjectCard
                    title='Daily Princetonian'
                    description="
                        The Daily Princetonian's campus website is a PWA that shows Princeton campus events, 
                        dining hall menus, and local store offerings. I wrote a Google Apps Script program that allows the business
                        team to automatically update the campus website with new campus events through a spreadsheet.
                    "
                    languages={[reactLogo, tsLogo, pythonLogo]}
                    images={dailyPrinceLogo}
                    projectImages={[dailyPrinceImage1, dailyPrinceImage2]}
                    projectLink={'http://campus.dailyprincetonian.com'}
                />
                <ProjectCard
                    title='Bubble'
                    description="
                        Bubble is an open source end to end encrypted (eventually) alternative to Life360. 
                        It is a cross-platform app that allows you to track your family and friends' location securely.
                        The frontend is built with React Native. 
                    "
                    languages={[reactLogo, tsLogo, appleLogo, androidLogo]}
                    images={bubbleLogo}
                    projectImages={[bubbleImage1, bubbleImage2]}
                    projectLink={'https://github.com/jbis9051/bubble'}
                />
                <ProjectCard 
                    title='BB Hub' 
                    description='
                        BB Hub is a web-app made for Blind Brook high school students that shows their schedule, 
                        homework assignments, lunch menu, games, meetings and more.
                        It is built using ExpressJS for the backend with a MongoDB database and EJS for the frontend.
                    '
                    images={bbHubLogo}
                    languages={[nodeLogo, mongoLogo, expressLogo, ejsLogo]}
                    projectImages={[bbHubImage1, bbHubImage2]}
                    projectLink={'https://github.com/johnnyr26/BB-Hub'}
                />
                <ProjectCard 
                    title='OvarScore' 
                    description='
                        OvarScore is a website used by many international ovarian cancer surgeons that guides them on 
                        treatment approach for ovarian cancer patients. It was presented at the International
                        Gynecologic Cancer Society Meeting and has been used in over 50 countries.
                    '
                    images={ovarscoreLogo}
                    languages={[jsLogo, htmlLogo]}
                    projectLink={'https://www.ovarscore.com'}
                />
                <ProjectCard
                    title='BB Scheduler'
                    description="
                        BB Scheduler is a website and module to generate iCal files and HTML tables based on Blind Brook's rotating block schedule and letter day calendar.
                        This website is built with React and TypeScript for the frontend and ExpressJS for backend.
                    "
                    images={bbSchedulerLogo}
                    languages={[reactLogo, tsLogo, expressLogo]}
                    projectLink={'https://github.com/jbis9051/Blind-Brook-Calendar-Creator'}
                />
                <ProjectCard
                    title='Schedule'
                    images={scheduleLogo}
                    description='
                        During my Junior year, the guidance couseling office asked me to create a website that would allow them to resolve scheduling
                        confilcts. This website is built with a NodeJS backend and an EJS frontend and was used for the 2020-21 and 2021-22 school year.
                    '
                    languages={[nodeLogo, expressLogo, ejsLogo, jsLogo]}
                    projectLink={'https://bbcounselors.herokuapp.com/'}
                />
            </div>
        </div>
    </div>
);

export default Projects;