import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; 
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const ProjectSwiper = ({ projectImages }) => (
    <Swiper
            pagination={{
                clickable: true
            }}
            modules={[Pagination]}
        >
            {
                projectImages && projectImages.map((image, key) => (
                    <SwiperSlide key={key} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img height='300px' src={image} />
                    </SwiperSlide>
                ))
            }
    </Swiper>
);

export default ProjectSwiper;